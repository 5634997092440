<template>
  <div class="flex flex-col items-center justify-start group">
    <div class="relative">
      <div class="product-is-new absolute z-20 m-2" v-if="selectedVariant?.isNew">
        {{ $t('app.new') }}
      </div>

      <div class="group">

        <DiscountPercent :selected-variant="selectedVariant" v-if="!showRateNextToPrice"/>

        <!--        <div-->
        <!--            class="flex justify-end z-30 cursor-pointer hover:text-primary sm:invisible sm:group-hover:visible"-->
        <!--            v-if="isAuth"-->
        <!--            @click="addWishlist(selectedVariant)">-->

        <!--          <HeartFilledIcon-->
        <!--              class="text-red h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
        <!--              v-if="selectedVariant.isFavorited"/>-->
        <!--          <HeartIcon-->
        <!--              class="text-lightgray-400 h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
        <!--              v-else/>-->
        <!--        </div>-->
        <!--        <div-->
        <!--            class="flex justify-end z-30 cursor-pointer hover:text-primary sm:invisible sm:group-hover:visible"-->
        <!--            v-if="!isAuth"-->
        <!--            @click="authModalIsVisible = true">-->
        <!--          <HeartIcon-->
        <!--              class="text-lightgray-400 h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
        <!--          />-->
        <!--        </div>-->

        <div class="rounded-lg relative border border-lightgray">
          <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="block bg-white">
            <div class="flex absolute z-20 justify-center text-center items-center w-full h-full"
                 v-if="!selectedVariant?.inStock">
              <span class="product-out-of-stock"><StockOutText/></span>
            </div>

            <div class="flex absolute z-20 justify-center text-center items-end w-full h-full"
                 v-if="selectedVariant?.isFreeShipping">
              <div class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                <BoltIcon class="h-5 w-5 mr-2 fill-primary"/>
                <span>{{ $t('app.free-shipping') }}</span>
              </div>
            </div>

            <NuxtImg
                v-if="selectedVariant?.thumbnail"
                :src="getImage(selectedVariant.thumbnail)"
                width="300"
                height="300"
                loading="lazy"
                :placeholder="[50, 50, 93, 3]"
                :quality="90"
                :alt="selectedVariant?.title"
                :class="{'product-img-out-of-stock' : !selectedVariant?.inStock}"
                class="z-1 rounded-lg object-contain aspect-square mx-auto"
            />

            <NuxtImg
                v-else
                fit="outside"
                alt=""
                class="z-1 rounded-lg object-contain aspect-square"
                width="300"
                height="300"
                src="/images/no-image.png"
            />
          </NuxtLinkLocale>

          <!-- Ürün Seçenekleri -->
          <SectionProductCardGroupOptions :product="product"/>

        </div>
        <div class="flex flex-col text-base text-primary">
          <div class="card-brand mt-2" v-if="!hideBrand">
            {{ product.brandName }}
          </div>
          <div class="font-normal text-[12px] md:text-[15px] line-clamp-2">{{ selectedVariant?.title }}</div>

          <!-- Fiyat Bilgisi -->
          <SectionProductCardPriceView :variant="selectedVariant"/>

        </div>

        <!-- Aksiyon Butonları -->
        <div class="hidden md:flex flex-wrap items-center sm:invisible sm:group-hover:visible gap-2"
             v-if="!isShowcaseMode && (isWholesaleUser || accessType !== 'b2b')">
          <div class="flex-auto">
            <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="card-button hover:bg-primary">
              <TriangleIcon class="h-3 w-3 mr-1"/>
              {{ $t('listing.view-product') }}
            </NuxtLinkLocale>
          </div>
          <div class="flex-auto">
            <button class="card-button hover:bg-secondary" @click="addToBasket(selectedVariant.variantId, 1, selectedVariant?.primaryStockData.rawCurrency, selectedWarehouse.value)">
              <AddToCartIcon class="h-4 w-4 mr-1"/>
              {{ $t('app.add-to-cart') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {HeartIcon, BoltIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import TriangleIcon from '~/assets/icons/triangle.svg'
import AddToCartIcon from '~/assets/icons/add-to-cart.svg'

import {collect} from "collect.js";

const props = defineProps(['product'])

const storeConfig = useStoreConfig()
const {accessType, baseConfig, showRateNextToPrice} = storeToRefs(storeConfig)

const {
  selectedWarehouse,
  hideBrand
} = useListing()

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

const variants = await collect(props.product.variants);

const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

const {addToBasket, addCartLoading} = await useBasket()
const {isWholesaleUser, authModalIsVisible} = await useCustomer()
const {addWishlist} = useWishlist()
</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>